<template>
<div class="article-page special-page special-suminoe-page">
<nav class="global-nav" role="navigation">
  <ul>
    <li>
      <router-link class="button dayrace-button" :to="{name:'Index'}">トップ</router-link>
    </li>
    <li>
      <router-link class="button dayrace-button" :to="{name:'DayRaceList',params:{date:today_str}}">本日のレース</router-link>
    </li>
  </ul>
</nav>
<nav class="bread-nav" aria-label="You are here:" role="navigation">
  <ul class="breadcrumbs">
    <li><router-link :to="{name:'Index'}">Top</router-link></li>
    <li>
      <span class="show-for-sr">Current: </span> 特集: {{ title }}
    </li>
  </ul>
</nav>
<div class="hero-section">
  <div class="hero-title">
    GI 第52回高松宮記念特別競走
    2024年11月1日～6日（ナイター開催）
  </div>
</div>
<div class="article-container">
<div class="article-wrapper" v-html="content"></div>
<div style="text-align:center;margin:40px auto;">
<Adsense
    class="adsbygoogle"
    data-ad-client="ca-pub-3076785709839281"
    data-ad-slot="9132319872"
    data-ad-format="rectangle"
    data-full-width-responsive="true">
</Adsense>
</div>
</div>
</div>
</template>
<script>
import pianoSend from '../mixins/PrefsMixin'
export default {
  name :'SpecialSuminoe202410',
  mixins: [pianoSend],
  data:function(){
    return {
      date:'',
      title:'',
      content:'',
      today:'',
      today_str:'',
      id:''
    }
  },
   head:function(){
    return {
      title: function(){
        return {
          inner:  this.$data.title + ' - GI 第52回高松宮記念特別競走 2024年11月1日～6日（ナイター開催）',
          separator: ' - ',
          complement: 'BOATNAVI報知'
        }
      }
    }
  },
  created(){
      this.$data.id = this.$route.params['id'];
      this.getData();

      this.$data.today = new Date();
      this.$data.today_str = '' + this.$data.today.getFullYear() + ('0'+(this.$data.today.getMonth()+1)).slice(-2) + ('0'+this.$data.today.getDate()).slice(-2);
  
  },
  beforeRouteUpdate(_to, _from, _next) {
      
        if(_to.name == 'SpecialSuminoe202411' && _from.name == 'SpecialSuminoe202411'){
            this.$data.id =  _to.params['id'];
            this.getData();
        }

      _next();
  },
  methods:{
     getData(){
        let loader = this.$loading.show({});
        let ts = Math.floor(Number(Date.now())/1000/60);
        this.axios.get('/special_contents/suminoe/'+this.$data.id+'.html?ts='+ts)
          .then((response) => {
             loader.hide();
            this.$data.content = response.data;

            let content_dom = document.createElement('div');
            content_dom.innerHTML = this.$data.content;
            this.$data.title = content_dom.getElementsByTagName('h1')[0].innerText;

            this.$emit('updateHead');
            this.pianoSend('記事',this.$data.title);
       
          })
          .catch((e) => { // eslint-disable-line
           
             loader.hide();
            this.$router.replace({ name:'NotFound'});
          });
     }
  }
}
</script>
<style lang="scss" scoped>
.special-suminoe-page{
    .hero-section{
       .hero-title{
          background-image:url(/special_contents/assets/images/suminoe/title_wide202411.svg);
             @media screen and (max-width:428px) {
              background-image:url(/special_contents/assets/images/suminoe/title_mobile202411.svg);
             } 
        } 
    }
}
</style>